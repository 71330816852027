<template>
	<div class="grid p-fluid">
		<div class="col-12 md:col-6">
			<div class="card">
				<h4 style="font-weight: 600;">Información General</h4>
				<div class="grid formgrid">
					<div class="col-12 mb-2 lg:col-8 lg:mb-0">
						<h5 style="margin-top: 1.5rem; margin-botton: 1rem;">Razón Social</h5>
						<InputText type="text" placeholder="Razón social" v-model="business.registeredName"></InputText>
					</div>
					<div class="col-12 mb-2 lg:col-4 lg:mb-0">
						<h5 style="margin-top: 1.5rem; margin-botton: 1rem;">RUC</h5>
						<InputText type="text" placeholder="RUC" v-model="business.identification"></InputText>
					</div>
				</div>
				<h5></h5>
				<div class="grid formgrid">
					<div class="col-12 mb-2 lg:col-6 lg:mb-0">
						<h5 style="margin-top: 1.5rem; margin-botton: 1rem;">Tipo de Contribuidor</h5>
						<Dropdown v-model="business.contribuitorType" :options="business.contribuitorTypes" optionLabel="name" placeholder="Select" />
					</div>
					<div class="col-12 mb-2 lg:col-6 lg:mb-0">
						<h5 style="margin-top: 1.5rem; margin-botton: 1rem;">Código de Contribuidor</h5>
						<InputText type="text" placeholder="Código" v-model="business.contribuitorCode"></InputText>
					</div>
				</div>
				<div class="grid formgrid">
					<div class="col-12 mb-2 lg:col-12 lg:mb-0">
						<h5 style="margin-top: 1.5rem; margin-botton: 1rem;">Obligado llevar Contabilidad</h5>
						<InputSwitch v-model="business.hasAccounting" />
					</div>
				</div>
				<h5></h5>
				<h4 style="font-weight: 600;">Firma Electrónica</h4>
				<div class="grid formgrid">
					<div class="col-12 mb-2 lg:col-12 lg:mb-0">
						<h5 style="margin-top: 1.5rem; margin-botton: 1rem;">Certificado</h5>
						<h6>{{ this.business.certName }}</h6>	
						<input type="file" class="form-control" accept=".p12" @change="onCertificateChanged($event)"/>
					</div>
					<div class="col-12 mb-2 lg:col-6 lg:mb-0">
						<h5 style="margin-top: 1.5rem; margin-botton: 1rem;">Contraseña de certificado</h5>
						<Password id="certPass" v-model="business.certPass" :feedback="false" placeholder="Contraseña" :toggleMask="true" class="w-full mb-3" inputClass="w-full" inputStyle="padding:1rem"></Password>
					</div>
					<div class="col-12 mb-2 lg:col-6 lg:mb-0">
						<h5 style="margin-top: 1.5rem; margin-botton: 1rem;">Válido hasta:</h5>
						<label>{{ this.business.expiresAt !== null ? this.business.expiresAt : "-" }}</label>
					</div>
				</div>
				<h5></h5>
				<div class="grid formgrid">
					<div class="col-12 mb-2 lg:col-4 lg:mb-0" style="margin-left: auto;">
						<Toast />
						<Button label="Guardar" class="p-button p-button-success mr-2 mb-2" @click="this.save()"/>
					</div>
				</div>
			</div>
		</div>
		<div class="col-12 md:col-6">
			<div class="card">
				<Button icon="pi pi-plus" class="p-button-rounded p-button-info mr-2 mb-2" style="position: absolute; top: 15px; right: 10px;" v-on:click="this.onShowEconActivityDialog"/>
				<h5>Actividades Económicas</h5>
				<label v-if="this.activities !== null && this.activities.length === 0">
					No hay actividades económicas registradas
				</label>
				<div class="grid formgrid" v-for="activity in this.activities" :key="activity.id">
					<div class="col-12 mb-2 lg:col-8 lg:mb-0">
						<div style="display: flex; width: 100%;">
							<Image :src="this.baseUrl + activity.logoUrl" alt="Logo del negocio" width="100" height="40" style="margin-right: 10px; padding: 20px 0px" preview />
							<div style="width: 100%;">
								<label style="margin: 1.5rem 0rem 0.2rem 0rem;"><b>Nombre Comercial</b></label><br>
								<label>{{ activity.name }}</label>
							</div>
						</div>
					</div>
					<div class="col-12 mb-2 lg:col-4 lg:mb-0">
						<div style="display: flex; text-align: right; padding: 1.5rem 0px;">
							<div style="margin: 0px 0px 0px auto; width: 1px;"></div>
							<Button icon="pi pi-pencil" class="p-button-rounded p-button-success mr-2 mb-2" @click="onEditEconActivity(activity)" />
							<RadioButton name="defaultActivity" :value="activity" v-model="this.business.defaultActivity" style="padding: 10px 0px; margin-left: 20px;" @click="onSelectEconActivity(activity)"/>
						</div>
					</div>
					<div style="width: 100%; height: 1px; background-color: lightgray"></div>
				</div>
			</div>

			<div class="card">
				<div style="display: flex;">
					<h5>Agente de Retención</h5>
					<InputSwitch v-model="business.makesRetention" style="margin-left: auto;" />
				</div>
				<div class="grid formgrid">
					<div class="col-12 mb-2 lg:col-4 lg:mb-0" style="margin: 20px 0px 0px auto;">
						<Toast />
						<Button label="Guardar" class="p-button p-button-success mr-2 mb-2" @click="this.save()"/>
					</div>
				</div>
			</div>
		</div>

		<Dialog v-model:visible="econActivityDialog" :style="{width: '450px'}" :header="((this.selectedActivity.id === null) ? 'Nueva ' : '') + 'Actividad Económica'" :modal="true" class="p-fluid" @after-hide="onHideEconActivityDialog">
			<div class="field">
				<label for="name">Nombre Comercial</label>
				<InputText id="name" v-model.trim="selectedActivity.name" required="true" autofocus />
			</div>
			<div class="field">
				<h5>Establecimientos y Ptos. emisión</h5>
				<div class="grid">
					<div class="col-12 md:col-12" v-for="point in this.points" :key="point.id">
						<div class="field-radiobutton mb-0">
							<RadioButton name="emitionPoint" :value="point" v-model="this.selectedActivity.emitionPoint" />
							<label>{{ (point.establishment.isMain ? "[MATRIZ] " : "") + point.establishment.code + " - " + point.code }}</label>
						</div>
					</div>
				</div>
			</div>
			<div class="field">
				<h5>Logotipo del Negocio</h5>
				<div class="grid formgrid">
					<div class="col-12 mb-2 lg:col-12 lg:mb-0">
						<Image ref="selectedActivityLogoImage" :src="this.$baseURL + this.selectedActivity.logoUrl" alt="Logo del negocio" width="100" style="margin: 0px 40px 10px 0px;" preview />
						<input type="file" class="form-control" accept="image/*" @change="onLogoImageChanged($event)"/>
					</div>
				</div>
			</div>
			<template #footer>
				<Button label="Cancelar" icon="pi pi-times" class="p-button-secondary mr-2 mb-2" @click="onHideEconActivityDialog"/>
				<Button label="Guardar" icon="pi pi-check" class="p-button-success mr-2 mb-2" @click="saveEconActivity" />
			</template>
		</Dialog>
	</div>
</template>
<script>
	import httpRequest from '../../service/httpRequest';	
	import Business from '../../models/business';
	import EconActivity from '../../models/econActivity';
	import EmitionPoint from '../../models/emitionPoint';

	export default {
		data() {
			return {
				business: new Business(),
				activities: null,
				selectedActivity: new EconActivity(),
				selectedEmitionPoint: null,
				points: [EmitionPoint],
				econActivityDialog: false,
				baseUrl: this.$baseURL
			}
		},
		created() {
			this.getBusiness();
			this.getEconActivities();
		},
		methods: {
			async getBusiness() {
				let response = await httpRequest.getBusiness();
				if (response.status == 200 && response.data !== null) {
					let business = new Business(response.data);
					this.business = business;
					this.business.defaultActivity = this.$store.getters.defaultActivity;
				} else if (response.status == 502) { // Unauthorized
					await this.$store.dispatch('Logout');
					this.$router.push('/login');
				}
			},
			async save() {
				const form = new FormData();
				if (this.business.commercialName !== null) { form.append('commercialName', this.business.commercialName) }
				if (this.business.registeredName !== null) { form.append('registeredName', this.business.registeredName) }
				if (this.business.identification !== null) { form.append('identification', this.business.identification) }
				if (this.business.contribuitorType !== null) { form.append('contribuitorType', this.business.contribuitorType.value) }
				if (this.business.contribuitorCode !== null) { form.append('contribuitorCode', this.business.contribuitorCode) }
				if (this.business.hasAccounting !== null) { form.append('hasAccounting', this.business.hasAccounting) }
				if (this.business.makesRetention !== null) { form.append('makesRetention', this.business.makesRetention) }
				if (this.business.logoFile !== null) { form.append('logoFile', this.business.logoFile) }
				if (this.business.certFile !== null) { form.append('certFile', this.business.certFile) }
				if (this.business.certPass !== null) { form.append('certPass', this.business.certPass) }
				
				let response = await httpRequest.saveBusiness(form);
				if (response.status === 200) {
					let business = new Business(response.data);
					this.business = business;
					await this.$store.dispatch('setBusiness', business);
					this.$toast.add({severity:'success', summary: 'Guardado', detail:'Los datos se actualizaron con éxito', life: 3000});
				}
			},
			async getEconActivities() {
				let response = await httpRequest.getEconActivities();
				if (response.status == 200) {
					let activitiesData = response.data.activities;
					let pointsData = response.data.points;

					// Parse economic activities
					this.activities = activitiesData.map(activity => { return new EconActivity(activity) });

					// Parse establishments and emition points
					this.points = pointsData.map(point => {  return new EmitionPoint(point) });
				}
			},
			async saveEconActivity() {
				const form = new FormData();
				if (this.selectedActivity.id !== null) { form.append('id', this.selectedActivity.id) }
				if (this.selectedActivity.name !== null) { form.append('name', this.selectedActivity.name) }
				if (this.selectedActivity.emitionPoint.id !== null) { form.append('point', this.selectedActivity.emitionPoint.id) }
				if (this.selectedActivity.logoFile !== null) { form.append('logoFile', this.selectedActivity.logoFile) }
				
				let response = await httpRequest.saveEconActivity(form);
				if (response.status === 200) {
					let activitiesData = response.data.activities;
					let pointsData = response.data.points;
					let businessData = response.data.business;

					// Parse economic activities
					this.activities = activitiesData.map(activity => { return new EconActivity(activity) });

					// Parse establishments and emition points
					this.points = pointsData.map(point => {  return new EmitionPoint(point) });

					// Parse business
					this.business = new Business(businessData);

					// Store default activity
					await this.$store.dispatch('setDefaultActivity', this.business.defaultActivyty);

					// Show alert and dismiss
					this.$toast.add({severity:'success', summary: 'Guardado', detail:'Los datos se actualizaron con éxito', life: 3000});
					this.econActivityDialog = false;
					this.selectedActivity = new EconActivity();
				}
			},
			onCertificateChanged(event) {
				this.business.certFile = event.target.files[0];
			},
			async onSelectEconActivity(activty) {
				let response = await httpRequest.setDefaultEconActivity(activty.id);
				if (response.status === 200) {
					let activity = new EconActivity(response.data);

					// Store default activity
					await this.$store.dispatch('setDefaultActivity', activity);

					this.$toast.add({severity:'success', summary: 'Actividad Seleccionada', detail: activity.name + ' se guardo como tu actividad económica seleccionada', life: 5000});
				}
			},
			onEditEconActivity(activity) {
				this.selectedActivity = new EconActivity(activity);
				this.selectedEmitionPoint = new EmitionPoint(activity.emitionPoint);
				this.econActivityDialog = true;
			},
			onShowEconActivityDialog() {
				this.econActivityDialog = true;
				this.selectedEmitionPoint = null;
			},
			onHideEconActivityDialog() {
				this.econActivityDialog = false;
				this.selectedActivity = new EconActivity();
			},
			onLogoImageChanged(event) {
				this.selectedActivity.logoFile = event.target.files[0];
			},
			activityIndex(activity) {
				var index = -1;
				if (activity.id === null) { index = -1; return index; }
				for (var i=0; i<this.activities.length; i++) {
					if (this.activities[i].id === activity.id) { index = i; break }
				}
				return index;
			},
			addActivitiesList(activity) {
				let index = this.activityIndex(activity);
				if (index > -1) { this.activities[index] = activity }
				else { this.activities = [activity, ...this.activities] } 
			},
		}
	}
</script>